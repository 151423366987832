import React from "react";

function WerkstattBesuch() {
    return (
        <div class="uk-container uk-padding">
            <h2>Werkstattbesuch</h2>
            
        </div>
    );
}

export default WerkstattBesuch;