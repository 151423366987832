import React from "react";

function Zimmervermietung() {
    return (
        <div class="uk-container uk-padding">
            <h2>Zimmervermietung</h2>
            <img class="uk-visible@m" src="/Zimmer_mit_Traumgarten.jpg" alt="Zimmer vermietung" width="70% !important" /> 
            <img class="uk-hidden@m" src="/Zimmer_mit_Traumgarten.jpg" alt="Zimmer vermietung" width="100% !important" />
        </div>
    );
}

export default Zimmervermietung;