import React from "react";

function Spezielles() {
    return (
        <div class="uk-container uk-padding">
            <h2>Spezielles</h2>
            <div class="uk-text-left">
    
            <p>Aktuell steht kein spezieller Kurs an. Schaut gern demnächst wieder vorbei.</p>
            <br></br>
            </div>
        </div>
    );
}

export default Spezielles;