import React from "react";

function Erwachsene() {
    return (
        <div class="uk-container uk-padding">
            <h2>Erwachsene</h2>
            <div class="uk-text-left">
            <p>In meinen Kursen habt ihr die Möglichkeit, in einer kleinen Gruppe verschiedene Materialien und unterschiedliche Techniken kennenzulernen und nach Lust und Laune eure Ideen umsetzen. Dabei unterstütze ich euch in eurem persönlichen Prozess und individuellen Ausdruck.
</p>
            <p>Wenn ihr Interesse an einem bestimmten Kurs habt, ruft gern an. Dann können wir alles weitere besprechen.</p>
            <p>Weitere aktuelle Kurse findet ihr unter <a href="/spezielles">Spezielles.</a></p>
            <br></br>
            </div>
            <div class="uk-child-width-1-2@m uk-grid uk-visible@m">
                <div>
                    <div class="uk-card uk-card-default">
                        <img src="./erwachsen_kurs1.jpg" width="1800" height="1200" alt="" />
                    </div>
                </div>
                <div>
                    <div class="uk-card uk-card-default">
                        <img src="./erwachsen_kurs2.jpg" width="1800" height="1200" alt="" />
                    </div>
                </div>
                <div>
                    <br></br><br></br>
                    <div class="uk-card uk-card-default">
                        <img src="./erwachsen_kurs3.jpg" width="1800" height="1200" alt="" />
                    </div>
                </div>
            </div>
            <img class="uk-hidden@m" src="/erwachsen_kurs1.jpg" alt="erwachsen_kurz" width="100% !important" />
            <br></br><br></br>
            <img class="uk-hidden@m" src="/erwachsen_kurs2.jpg" alt="erwachsen_kurz" width="100% !important" /> 
            <br></br><br></br>
            <img class="uk-hidden@m" src="/erwachsen_kurs3.jpg" alt="erwachsen_kurz" width="100% !important" /> 
        </div>
    );
}

export default Erwachsene;