import React from "react";

const images = [
    {href : "./Impressionen/1.jpeg", caption: ""},
    {href : "./Impressionen/2.jpeg", caption: ""},
    {href : "./Impressionen/3.jpeg", caption: ""},
    {href : "./Impressionen/4.jpeg", caption: ""},
    {href : "./Impressionen/5.jpeg", caption: ""},
    {href : "./Impressionen/6.jpeg", caption: ""},
    {href : "./Impressionen/7.jpeg", caption: ""},
    {href : "./Impressionen/8.jpeg", caption: ""},
    {href : "./Impressionen/9.jpeg", caption: ""},
    {href : "./Impressionen/11.jpeg", caption: ""},
    {href : "./Impressionen/12.jpeg", caption: ""},
    {href : "./Impressionen/13.jpeg", caption: ""},
    {href : "./Impressionen/14.JPG", caption: ""},
    {href : "./Impressionen/15.JPG", caption: ""},
    {href : "./Impressionen/16.jpg", caption: ""},
    {href : "./Impressionen/17.jpg", caption: ""},
    {href : "./Impressionen/18.jpg", caption: ""},
    {href : "./Impressionen/19.jpg", caption: ""},
    {href : "./Impressionen/20.jpg", caption: ""},
    {href : "./Impressionen/21.jpg", caption: ""},
    {href : "./Impressionen/22.jpg", caption: ""},
    {href : "./Impressionen/23.jpg", caption: ""},
    {href : "./Impressionen/24.jpg", caption: ""},
    {href : "./Impressionen/25.jpg", caption: ""},
    {href : "./Impressionen/26.jpg", caption: ""},
    {href : "./Impressionen/27.jpg", caption: ""},
    {href : "./Impressionen/28.jpg", caption: ""},
    {href : "./Impressionen/29.jpg", caption: ""},
    {href : "./Impressionen/30.jpg", caption: ""},
    {href : "./Impressionen/31.jpg", caption: ""},
    {href : "./Impressionen/32.jpg", caption: ""},
    {href : "./Impressionen/33.jpg", caption: ""},
    {href : "./Impressionen/34.jpg", caption: ""},
    {href : "./Impressionen/35.jpg", caption: ""},
    {href : "./Impressionen/36.jpg", caption: ""},
    {href : "./Impressionen/37.jpg", caption: ""},
    {href : "./Impressionen/38.jpg", caption: ""},
    {href : "./Impressionen/39.jpg", caption: ""},
    {href : "./Impressionen/40.jpg", caption: ""},
    {href : "./Impressionen/41.jpg", caption: ""},
    {href : "./Impressionen/42.jpg", caption: ""},
    {href : "./Impressionen/43.jpg", caption: ""},
    {href : "./Impressionen/44.jpg", caption: ""},
    {href : "./Impressionen/45.jpg", caption: ""},
    {href : "./Impressionen/46.jpg", caption: ""},
    {href : "./Impressionen/47.jpg", caption: ""},
    {href : "./Impressionen/48.jpg", caption: ""},
    {href : "./Impressionen/49.jpg", caption: ""},
    {href : "./Impressionen/50.jpg", caption: ""},
    {href : "./Impressionen/51.jpg", caption: ""},
    {href : "./Impressionen/52.jpg", caption: ""},
    {href : "./Impressionen/53.jpg", caption: ""},
    {href : "./Impressionen/54.jpg", caption: ""},
    {href : "./Impressionen/55.jpg", caption: ""},
    {href : "./Impressionen/56.jpg", caption: ""},
    {href : "./Impressionen/57.jpg", caption: ""},
    {href : "./Impressionen/58.jpg", caption: ""},

];

function Impressions() {
    const reversedImages = images.slice().reverse();
    return(
        <div class="uk-container">
        <div class="uk-child-width-1-5@m uk-grid" uk-lightbox="animation: slide">
            {
                // eslint-disable-next-line array-callback-return
                reversedImages.map((image) => (
                    <div class="uk-card uk-card-body" style={{display: "flex", alignItems: "center" }}>
                        <div class="uk-display-block">
                            <a class="uk-inline uk-margin-auto uk-cover-container" href={image.href} data-caption={image.caption}>
                                <img class="uk-visible@m" src={image.href.replace("./Impressionen/", "./thumbnails/Impressionen/")} width="1500" height="1200" alt={image.href.replace("./Impressionen/", "")} loading="lazy" uk-cover/>
                                <img class="uk-hidden@m" src={image.href.replace("./Impressionen/", "./thumbnails/Impressionen/")} width="500" height="500" alt={image.href.replace("./Impressionen/", "")} loading="lazy" uk-cover/>
                            </a>
                        </div>
                    </div>
                ))
            }
        </div>
        <br></br><br></br>
    </div>
    );
}

export default Impressions;