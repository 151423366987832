import React from "react";

function Kinder() {
    return (
        <div class="uk-container uk-padding">
            <h2>Kinder</h2>
            <div class="uk-text-left">
            <p>Spielerisch und mit viel Experimentierfreude können Kinder nach Lust und Laune ihre kreativen Ideen zum Ausdruck bringen.</p>

            <br></br>
            <p>Was mir wichtig ist</p>
            <ul>
                <li>Individuelle Kreativitätsförderung in Kleingruppen</li>
                <li>Kursangebot mit unterschiedlichen Materialien</li>
                <li>Gezielte Unterstützung "Neues" auszuprobieren</li>
                <li>Ausdruck und Selbstbewusstsein stärken</li>
                <li>Förderung der Konzentration und Motorik</li>
                <li>Entwicklung (Be-greifen)</li>
            </ul>
            <br></br>
            <p><i><b>Entwicklung bedeutet für mich: </b>Kreative Prozesse zu erleben und dabei auch mit Misserfolgen umzugehen. Denn gerade sie ermöglichen neue Lösungen zu finden und können eine positive Weiterentwicklung anstoßen.</i></p>
            <br></br>
            </div>
            <br></br>
            <img class="uk-visible@m" src="/kinder_kurz.jpg" alt="kinder_kurz" width="60% !important" /> 
            <img class="uk-hidden@m" src="/kinder_kurz.jpg" alt="kinder_kurz" width="100% !important" />
            <br></br>
        

        </div>
    );
}

export default Kinder;